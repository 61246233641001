import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import { Language } from '../../../language/languages-list/core/_models'
// import { District, Province } from '../../../country/countries-list/core/_models'
export type Currency = {
  id?: any
  name?: string
  code?: string
  symbol?:string
  country_id?: any
  exchange_rate_per_usd?: any
  create_at?: Date
  updated_at?: Date
}
export type Country = {
  id?: any
  country_name?: string
  country_telecom_code?:string
  country_flag_image?:string
  country_flag_image_url?:string
  language_id?: any
  currency?: Currency
  language?:Language
  updated_at?: Date
  created_at?: Date
}

export type Province = {
  id?: any
  province_name?: string
  country_id?: string
  currency_id?: string
}
export type District = {
  id?: any
  district_name?: string
  province_id?: string
  currency_id?: string
}
export type CountriesQueryResponse = WatandarOnlineResponse<Country[]>

export const initialCountry: Country = {
  country_name: '',
  country_telecom_code:'',
  id: '',
  language_id: 1,
  currency: {
    id: '',
    name: '',
    code: '',
    country_id: 1,
    exchange_rate_per_usd: '',
  },
}
