import { ID, WatandarOnlineResponse, Response } from '../../../../../_metronic/helpers'
import { Country } from '../../../country/countries-list/core/_models'

// import { District, Province } from '../../../companycode/companycodes-list/core/_models'
export type Company = {
  id?: any
  company_name?: string
  company_logo: string
  country: Country
}
export type CompanyCode = {
  id?: any
  company_Id?:any
  reserved_digit?:any
  company?:Company
  updated_at?: Date
  created_at?: Date
}
export type CompanyCodesQueryResponse = WatandarOnlineResponse<CompanyCode[]>

export const initialCompanyCode: CompanyCode = {
  id: '',
  company_Id: '',
  reserved_digit:'',
  company: {
    id: '',
    company_name: "",
    company_logo: "",
    country: {
      country_name: '',
      id: '',
      language_id: 1,
      currency: {
        id: '',
        name: '',
        code: '',
        country_id: 1,
        exchange_rate_per_usd: '',
      },
    }
  }
}
