import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
// import { District, Province } from '../../../currency/currencies-list/core/_models'
export type Currency = {
  id?: any
  name?: string
  code?: string
  symbol?:string
  currency_id?: any
  exchange_rate_per_usd?: string
  create_at?: Date
  updated_at?: Date
}
export type CurrenciesQueryResponse = WatandarOnlineResponse<Currency[]>

export const initialCurrency: Currency = {
    id: '',
    name: '',
    code: '',
    symbol:'',
    currency_id: 1,
    exchange_rate_per_usd: '',
}
