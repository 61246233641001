import { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  ListsWidget6,
  StatisticsWidget5,
  MixedWidget3,
} from '../../../_metronic/partials/widgets';
import axios from 'axios';
import { CurrencyContext } from '../../../_metronic/currency/CurrencyContextProvider';
import {
  FaShoppingCart,
  FaClipboardList,
  FaDollarSign,
  FaChartLine,
  FaUsers,
  FaBuilding,
  FaTags,
  FaBoxes,
} from 'react-icons/fa';

interface AdminData {
  today_orders: any;
  total_orders: any;
  total_sale: any;
  total_profit: any;
  today_sale: any;
  today_profit: any;
  total_resellers: any;
  total_companies: any;
  total_services: any;
  total_bundles: any;
}

const DashboardPage: FC = () => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const DASHBOARD_URL = `${API_URL}/admin/dashboard`;
  const intl = useIntl();
  const [adminData, setAdminData] = useState<AdminData | null>(null);
  const currencyContext = useContext(CurrencyContext);

  useEffect(() => {
    axios
      .get(DASHBOARD_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setAdminData(data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaShoppingCart />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData.today_orders : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TODAYORDER' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaClipboardList />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData.total_orders : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALORDER' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaDollarSign />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? `${currencyContext.symbol} ${adminData?.total_sale?.toFixed(2)}` : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALSALE' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            icon={<FaChartLine />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? `${currencyContext.symbol} ${adminData?.total_profit?.toFixed(2)}` : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALPROFIT' })}
          />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaDollarSign />}
            color='white'
            iconColor='primary'
            title={adminData ? `${currencyContext.symbol} ${adminData?.today_sale?.toFixed(2)}` : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TODAYSALE' })}
          />
        </div>

        <div className='col-xl-6'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            icon={<FaChartLine />}
            color='white'
            iconColor='primary'
            title={adminData ? `${currencyContext.symbol} ${adminData?.today_profit?.toFixed(2)}` : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TODAYPROFIT' })}
          />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaUsers />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData?.total_resellers : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALRESELLER' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaBuilding />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData?.total_companies : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALCOMPANY' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            icon={<FaTags />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData?.total_services : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALSERVICE' })}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            icon={<FaBoxes />}
            color='secondary'
            iconColor='primary'
            titleColor='black'
            descriptionColor='primary'
            title={adminData ? adminData?.total_bundles : intl.formatMessage({ id: 'APP.GENERAL.LOADING' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALBUNDLE' })}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        {/* <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
        </div> */}

        {/* <div className='col-xl-4'>
          <MixedWidget3
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='250px'
          />
        </div> */}
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
