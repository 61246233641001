import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
// import { District, Province } from '../../../province/provinces-list/core/_models'
export type Currency = {
  id?: any
  name?: string
  code?: string
  province_id?: any
  exchange_rate?: string
  create_at?: Date
  updated_at?: Date
}
export type Country = {
  id?: any
  country_name?: string
  language_id?: any
  country?: Currency
  updated_at?: Date
  created_at?: Date
}

export type Province = {
  id?: any
  province_name?: string
  country?: Country
  country_id?: string
}
export type District = {
  id?: any
  district_name?: string
  province_id?: string
  country_id?: string
}
export type ProvincesQueryResponse = WatandarOnlineResponse<Province[]>

export const initialProvince: Province = {
  id: '',
  province_name: '',
  country:{

  }
}
