import axios, {AxiosResponse} from 'axios'
import {ID, WatandarOnlineResponse, Response, openWhatsApp} from '../../../../../_metronic/helpers'
import {Order, OrdersQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ORDER_URL = `${API_URL}/admin/orders`
const GET_ORDERS_URL = `${API_URL}/admin/orders`

const getOrders = (query: string): Promise<OrdersQueryResponse | WatandarOnlineResponse<Order[]>> => {
  return axios
    .get(`${GET_ORDERS_URL}?${query}`)
    .then((d: AxiosResponse<WatandarOnlineResponse<Order[]>>) => {
      return d.data
    })
}

const getOrderById = async (id: ID): Promise<Order> => {
  try {
    const {data} = await axios.get(`${ORDER_URL}/${id}`)
    return data?.data?.order
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${ORDER_URL}/${id}`).then((response: AxiosResponse<Order>) => response.data)
}
const createOrder = (order: Order): Promise<Order | undefined> => {
  return axios
    .post(ORDER_URL, order)
    .then((response: AxiosResponse<Response<Order>>) => response.data)
    .then((response: Response<Order>) => response.data)
}

const updateOrder = (order: Order): Promise<Order | undefined> => {
  return axios
    .put(`${ORDER_URL}/${order.id}`, order)
    .then((response: AxiosResponse<Response<Order>>) => response.data)
    .then((response: Response<Order>) => response.data)
}

const deleteOrder = (orderId: ID): Promise<void> => {
  return axios.delete(`${ORDER_URL}/${orderId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}
const confirmOrder = (orderId: ID): Promise<void> => {
  return axios.get(`${ORDER_URL}/confirm-order/${orderId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
    const orderData = response.data.data.order;
  
      const orderID = orderData.id;
      const resellerName = orderData.reseller.reseller_name;
      const bundleTitle = orderData.bundle.bundle_title;
      const account = orderData.rechargeble_account;
      const price = orderData.bundle.selling_price;
      const serviceName = orderData.bundle.service.company.company_name;
      const category = orderData.bundle.service.service_category.category_name;
  
      // Create the WhatsApp message
      const whatsappMessage = `🎉 Order Confirmed Successfully! 🎉\n\nOrder ID: ${orderID}\nReseller: ${resellerName}\nBundle: ${bundleTitle}\nAccount: ${account}\nPrice: $${price}\nService: ${serviceName}\nCategory: ${category}\n\nThank you for your order. Your purchase is now confirmed.`;
      // openWhatsApp(+93700930683,whatsappMessage)
  })
}
const rejectOrder = (orderId: ID,rejectReason:any): Promise<void> => {
  return axios.get(`${ORDER_URL}/reject-order/${orderId}`, {
    params: { rejectReason },
  }
  ).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
    const orderData = response.data.data.order;
  
      const orderID = orderData.id;
      const resellerName = orderData.reseller.reseller_name;
      const bundleTitle = orderData.bundle.bundle_title;
      const account = orderData.rechargeble_account;
      const price = orderData.bundle.selling_price;
      const serviceName = orderData.bundle.service.company.company_name;
      const category = orderData.bundle.service.service_category.category_name;
  
      // Create the WhatsApp message
      const whatsappMessage = `🎉 Sorry! Due to some reason order rejected... 🎉\n\nOrder ID: ${orderID}\nReseller: ${resellerName}\nBundle: ${bundleTitle}\nAccount: ${account}\nPrice: $${price}\nService: ${serviceName}\nCategory: ${category}\n\nThank you for your order. Your purchase is now rejected.`;
      // openWhatsApp(+93700930683,whatsappMessage)
  })
}
const deleteSelectedOrders = (orderIds: Array<ID>): Promise<void> => {
  const requests = orderIds.map((id) => axios.delete(`${ORDER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getOrders,
  deleteOrder,
  confirmOrder,
  rejectOrder,
  deleteSelectedOrders,
  getOrderById,
  createOrder,
  updateOrder,
}
