/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { FaTachometerAlt, FaUsers, FaTags, FaBoxes, FaMoneyBillAlt, FaBuilding, FaCode, FaGlobe, FaLanguage, FaCoins, FaAd, FaCogs } from 'react-icons/fa'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon={<FaTachometerAlt />}
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      <AsideMenuItem
        to='/admin/pages/resellers'
        icon={<FaUsers />}
        title={intl.formatMessage({ id: 'MENU.RESELLER' })}
      />
      <AsideMenuItem
        to='/admin/pages/service_category'
        icon={<FaTags />}
        title={intl.formatMessage({ id: 'MENU.SERVICECATEGORY' })}
      />
      <AsideMenuItem
        to='/admin/pages/services'
        icon={<FaBoxes />}
        title={intl.formatMessage({ id: 'MENU.SERVICE' })}
      />
      <AsideMenuItem
        to='/admin/pages/bundles'
        icon={<FaBoxes />}
        title={intl.formatMessage({ id: 'MENU.BUNDLE' })}
      />
      <AsideMenuItem
        to='/admin/pages/orders'
        icon={<FaBoxes />}
        title={intl.formatMessage({ id: 'MENU.ORDER' })}
      />
      <AsideMenuItem
        to='/admin/pages/transactions'
        icon={<FaMoneyBillAlt />}
        title={intl.formatMessage({ id: 'MENU.MONEYTRANSACTIONS' })}
      />
      <AsideMenuItem
        to='/admin/pages/companies'
        icon={<FaBuilding />}
        title={intl.formatMessage({ id: 'MENU.COMPANY', defaultMessage: "Company" })}
      />
      <AsideMenuItem
        to='/admin/pages/company_codes'
        icon={<FaCode />}
        title={intl.formatMessage({ id: 'MENU.COMPANYCODE', defaultMessage: "Company Code" })}
      />
      <AsideMenuItem
        to='/admin/pages/countries'
        icon={<FaGlobe />}
        title={intl.formatMessage({ id: 'MENU.COUNTRY' })}
      />
      <AsideMenuItem
        to='/admin/pages/provinces'
        icon={<FaGlobe />}
        title={intl.formatMessage({ id: 'MENU.PROVINCE' })}
      />
      <AsideMenuItem
        to='/admin/pages/districts'
        icon={<FaGlobe />}
        title={intl.formatMessage({ id: 'MENU.DISTRICT' })}
      />
      <AsideMenuItem
        to='/admin/pages/languages'
        icon={<FaLanguage />}
        title={intl.formatMessage({ id: 'MENU.LANGUAGE' })}
      />
      <AsideMenuItem
        to='/admin/pages/currencies'
        icon={<FaCoins />}
        title={intl.formatMessage({ id: 'MENU.CURRENCY' })}
      />
      <AsideMenuItem
        to='/admin/pages/advertisements'
        icon={<FaAd />}
        title={intl.formatMessage({ id: 'MENU.ADVERTISEMENT' })}
      />
        <AsideMenuItem
        to='/admin/pages/app_setting'
        icon={<FaCogs />}
        title={intl.formatMessage({ id: 'MENU.APPSETTING' })}
      />
    </>
  )
}
