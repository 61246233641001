import React, { useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios'
import Swal from 'sweetalert2'

const AppSettingPage = () => {
  const [isSyncing, setIsSyncing] = useState(false)

  const advertisementsBreadcrumbs: Array<PageLink> = [
    {
      title: 'settings',
      path: '/admin/pages/app_setting',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleSync = async () => {
    setIsSyncing(true)
    try {
      const API_URL = process.env.REACT_APP_THEME_API_URL
      const response = await axios.post(`${API_URL}/admin/telegram-chat-ids/fetch`)
      console.log('Sync response:', response.data)
      Swal.fire({
        title: 'Success!',
        text: 'Telegram groups synced successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    } catch (error) {
      console.error('Error during sync:', error)
      Swal.fire({
        title: 'Error!',
        text: 'There was an error syncing the telegram groups.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setIsSyncing(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={advertisementsBreadcrumbs}>Application General Setting</PageTitle>
      
      <div className="container">
        {/* Telegram Groups Sync Section */}
        <div className="card py-5">
          <div className="card-header">
            <h3>Telegram Groups</h3>
          </div>
          <div className="card-body">
            <p className='text-muted mb-4'>
              Click the button below to sync Telegram groups with the application. This will ensure that all the latest groups are updated in the system.
            </p>
            <button type='button' className='btn btn-primary' onClick={handleSync}>
              {'Sync Telegram Groups'}
              {isSyncing && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        
        {/* Another Settings Section */}
      </div>
    </>
  )
}

export default AppSettingPage
