import axios, {AxiosResponse} from 'axios'
import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import {Currency, CurrenciesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CURRENCY_URL = `${API_URL}/admin/currencies`
const GET_CURRENCIES_URL = `${API_URL}/admin/currencies`

const getCurrencies = (query: string): Promise<CurrenciesQueryResponse | WatandarOnlineResponse<Currency[]>> => {
  return axios
    .get(`${GET_CURRENCIES_URL}?${query}`)
    .then((d: AxiosResponse<WatandarOnlineResponse<Currency[]>>) => {
      return d.data
    })
}

const getCurrencyById = async (id: ID): Promise<Currency> => {
  try {
    const {data} = await axios.get(`${CURRENCY_URL}/${id}`)
    return data?.data?.currency
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${CURRENCY_URL}/${id}`).then((response: AxiosResponse<Currency>) => response.data)
}
const createCurrency = (currency: Currency): Promise<Currency | undefined> => {
  return axios
    .post(CURRENCY_URL, currency)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Currency>) => response.data)
}

const updateCurrency = (currency: Currency): Promise<Currency | undefined> => {
  return axios
    .put(`${CURRENCY_URL}/${currency.id}`, currency)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Currency>) => response.data)
}

const deleteCurrency = (currencyId: ID): Promise<void> => {
  return axios.delete(`${CURRENCY_URL}/${currencyId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedCurrencies = (currencyIds: Array<ID>): Promise<void> => {
  const requests = currencyIds.map((id) => axios.delete(`${CURRENCY_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success("Action completed...",{position:'top-right'})

  })
}

export {
  getCurrencies,
  deleteCurrency,
  deleteSelectedCurrencies,
  getCurrencyById,
  createCurrency,
  updateCurrency,
}
