import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import { Currency } from '../../../currency/currencies-list/core/_models'
// import { Country, District, Province } from '../../../country/countries-list/core/_models'
export type Reseller = {
  id?: ID
  user_id?: number
  uuid?: string
  reseller_name?: string
  contact_name?: string
  reseller_type?: string
  email_verified_at?: string
  account_password?: string
  personal_pin?: string
  remember_token?: string
  profile_image_url?: File |string |null
  email?: string
  phone?: string
  country_id?:any
  province_id?:any 
  districts_id?:any 
  is_reseller_verified?: number
  status?: number
  balance?: number
  loan_balance?: number
  updated_at?: Date
  created_at?: Date
  profile_image?:File;
  currency_preference_id?:any
  currency?:Currency
  parent_reseller_profile_image_url?:any
  parent_reseller_phone?:string
  parent_reseller_name?:string
  
}

export type ResellersQueryResponse = WatandarOnlineResponse<Reseller[]>

export const initialReseller: Reseller = {
  reseller_name: '',
  contact_name: '',
  profile_image_url:"",
  currency_preference_id:'',
  email: '',
  phone: '',
  balance: 0,
  country_id:'',
  province_id:'',
  districts_id:''
}
