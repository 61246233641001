import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import { Bundle } from '../../../bundle/bundles-list/core/_models';
import { Currency } from '../../../country/countries-list/core/_models'
import { Reseller } from '../../../reseller/resellers-list/core/_models';
import { Service } from '../../../service/services-list/core/_models'
// import { District, Province } from '../../../order/orders-list/core/_models'
export type Order = {
  id?: any;
    reseller_id?: any;
    rechargeble_account?: string;
    bundle?: any | Bundle;
    reseller?:any | Reseller
    transaction_id?: any;
    is_paid?: any;
    status?: any;
    created_at?: Date;
    updated_at?: Date;
}

export type OrdersQueryResponse = WatandarOnlineResponse<Order[]>

export const initialOrder: Order = {
  id:"",
  reseller_id:"",
  rechargeble_account:"",
  bundle:{
    id: '',
  bundle_name: '',
  bundle_code: "quia",
  service_id: 2,
  bundle_title: "",
  bundle_description: "",
  buying_price: "",
  selling_price: "",
  bundle_image_url: null,
  currency_id: 2,
  service:{
    id: '',
    service_name: '',
    service_category: {
      id: '',
      category_name: ''
    },
    company: {
      id: '',
      company_name: "",
      company_logo: "",
      country: {
        country_name: '',
        id: '',
        language_id: 1,
        currency: {
          id: '',
          name: '',
          code: '',
          country_id: 1,
          exchange_rate: '',
        },
      },
    },
  },
  currency:{
    id: '',
    name: '',
    code: '',
    country_id: 1,
    exchange_rate: '',
  }
  },
  reseller:{
    reseller_name: '',
  contact_name: '',
  profile_image_url:"",
  email: '',
  phone: '',
  balance: 0,
  country_id:'',
  province_id:'',
  districts_id:''
  }
  
}
