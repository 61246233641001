// @ts-nocheck
import {Column} from 'react-table'
import {CompanyInfoCell} from './CompanyInfoCell'
import {CompanyLastLoginCell} from './CompanyLastLoginCell'
import {CompanyTwoStepsCell} from './CompanyTwoStepsCell'
import {CompanyActionsCell} from './CompanyActionsCell'
import {CompanySelectionCell} from './CompanySelectionCell'
import {CompanyCustomHeader} from './CompanyCustomHeader'
import {CompanySelectionHeader} from './CompanySelectionHeader'
import {Company} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const companiesColumns: ReadonlyArray<Column<Company>> = [
  {
    Header: (props) => <CompanySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CompanySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='COMPANY.TABLE.COLUMN.COMPANYIMAGE' />} className='min-w-125px' />,
    id: 'company_logo',
    Cell: ({...props}) => <CompanyInfoCell company={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='COMPANY.TABLE.COLUMN.COMPANYNAME' />} className='min-w-125px' />,
    id: 'company_name',
    accessor:'company_name'
  },
  {
    Header: (props) => <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='COMPANY.TABLE.COLUMN.COUNTRYNAME' />} className='min-w-125px' />,
    id: 'country.country_name',
    accessor:'country.country_name'
  },
  {
    Header: (props) => <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='COMPANY.TABLE.COLUMN.TELEGRAMCAHTID' />} className='min-w-125px' />,
    id: 'telegram_chat_id.chat_id',
    accessor:'telegram_chat_id.chat_id'
  },
  {
    Header: (props) => <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='COMPANY.TABLE.COLUMN.CHATGROUPNAME' />} className='min-w-125px' />,
    id: 'telegram_chat_id.group_name',
    accessor:'telegram_chat_id.group_name'
  },

  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CompanyActionsCell id={props.data[props.row.index].id} />,
  },
]

export {companiesColumns}
