import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialCompany, Company } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CompaniesListLoading } from '../components/loading/CompaniesListLoading'
import { createCompany, updateCompany } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { Country } from '../../../country/countries-list/core/_models'
import axios from 'axios'

type Props = {
  isCompanyLoading: boolean
  company: Company
}
export interface TelegramId{
  id?: any
  chat_id?:string,
  group_name?:string
}
const editCompanySchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const CompanyEditModalForm: FC<Props> = ({ company, isCompanyLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const COUNTRY_URL = `${API_URL}/admin/countries`;
  const TELEGRAM_URL = `${API_URL}/admin/telegram-chat-ids`;
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [countries, setCountries] = useState<[Country]>();
  const [telegram_chat_ids, setTelegramIds] = useState<[TelegramId]>();
  useEffect(() => {
    // Make the API request using Axios
    axios
      .get(COUNTRY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCountries(data.data.countries);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    // Make the API request using Axios
    axios
      .get(TELEGRAM_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setTelegramIds(data.data.telegram_chat_ids);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [companyForEdit] = useState<Company>({
    ...company,
    company_name: company.company_name || initialCompany.company_name,
    company_logo: company.company_logo || initialCompany.company_logo,
    country: company.country?.id || initialCompany.country?.id
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: companyForEdit,
    validationSchema: editCompanySchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCompany(values)
        } else {
          await createCompany(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      formik.setFieldValue('company_logo', file);
    }
  };
  return (
    <>
      <form id='kt_modal_add_company_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_company_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_company_header'
          data-kt-scroll-wrappers='#kt_modal_add_company_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>
              <FormattedMessage id='RESELLER.FORM.INPUT.PROFILE' />
            </label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
                style={{ backgroundImage: `url('${selectedImage?selectedImage:company.company_logo}')` }}            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                  style={{ backgroundImage: `url('${selectedImage?selectedImage:company.company_logo}')` }}              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CHANGEPROFILE' })}
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input type='file'
                  name='profile_image_url' onChange={(event)=>handleImageChange(event)} accept='.png, .jpg, .jpeg' />
                <input type='hidden' name='profile_image_url_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CANCELPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                onClick={(e) => setSelectedImage('')}
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.REMOVEPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'></div>
            {/* end::Hint */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='COMPANY.FORM.INPUT.COMPANYNAME' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'COMPANY.FORM.PLACEHOLDER.COMPANYNAME' })}
              {...formik.getFieldProps('company_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.company_name && formik.errors.company_name },
                {
                  'is-valid': formik.touched.company_name && !formik.errors.company_name,
                }
              )}
              type='text'
              name='company_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isCompanyLoading}
            />
            {/* end::Input */}
            {formik.touched.company_name && formik.errors.company_name && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.company_logo}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='COMPANY.FORM.INPUT.COUNTRYNAME' />
          </label>
            <select
              {...formik.getFieldProps('country_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.country_id && formik.errors.country_id },
                {
                  'is-valid': formik.touched.country_id && !formik.errors.country_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCompanyLoading}

            ><option value='WorldWide' defaultValue={"WorldWide"}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {countries && countries.map(country => (
                <option value={country.id}>{country.country_name}</option>
              ))}
            </select>
            {formik.touched.country_id && formik.errors.country_id && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.district_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='COMPANY.FORM.INPUT.TELEGRAMID' />
          </label>
            <select
              {...formik.getFieldProps('telegram_chat_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.telegram_chat_id && formik.errors.telegram_chat_id },
                {
                  'is-valid': formik.touched.telegram_chat_id && !formik.errors.telegram_chat_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCompanyLoading}

            ><option value='WorldWide' defaultValue={"WorldWide"}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {telegram_chat_ids && telegram_chat_ids.map(telegramId => (
                <option value={telegramId.id}>{telegramId.group_name} - {telegramId.chat_id}</option>
              ))}
            </select>
            {formik.touched.telegram_chat_id && formik.errors.telegram_chat_id && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.district_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-companies-modal-action='cancel'
            disabled={formik.isSubmitting || isCompanyLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-companies-modal-action='submit'
            disabled={isCompanyLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCompanyLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCompanyLoading) && <CompaniesListLoading />}
    </>
  )
}

export { CompanyEditModalForm }
